import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Menu from '../../components/navigation';
import ProjectsText from '../../components/projects/text';
import ProjecsGallery from '../../components/projects/gallery';
import ProjectItem from '../../components/projects/item';
import Image from '../../components/projects/image';

import logo from '../../images/projects/rf/logo.svg';

import img1 from '../../images/projects/rf/img1.jpg';
import img12x from '../../images/projects/rf/img1@2x.jpg';
import img2 from '../../images/projects/rf/img2.jpg';
import img3 from '../../images/projects/rf/img3.jpg';
import img32x from '../../images/projects/rf/img3@2x.jpg';
import img4 from '../../images/projects/rf/img4.jpg';
import img42x from '../../images/projects/rf/img4@2x.jpg';

const title = 'R&F Steuerberatungsgesellschaft';
const text =
	'Der neue Auftritt der Steuerberatungsgesellschaft „R&F“ soll frische Impulse setzen: Schlichtheit, Klarheit und Modernität. Dass es hier jedoch um weitaus mehr geht als bloße Zahlen und Fakten, betont die grün-türkisfarbene Akzentuierung: Das Unternehmen hebt sich insbesondere auch durch dessen Lebendigkeit, Offenheit und kundenorientierte Beratung heraus, bei der stets die Menschen im Vordergrund stehen.';

/**
 * Page
 */
const Page = () => {
	return (
		<Layout>
			<SEO title="" />
			<Menu selected="projekte" />
			<ProjectsText text={text} title={title} />
			<div className="page-width align-center"></div>
			<ProjecsGallery>
				<ProjectItem>
					<img src={logo} alt="" className="rf-logo" />
				</ProjectItem>
				<ProjectItem>
					<Image src={img1} src2={img12x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img2} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img3} src2={img32x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img4} src2={img42x} />
				</ProjectItem>
			</ProjecsGallery>
		</Layout>
	);
};

export default Page;
